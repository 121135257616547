/** ***********************************************************************************
 * Website / Atoms / Typography / Paragraph
 *********************************************************************************** */

p {
  // line-height: 1.222;

  &.small {
    font: var(--lm-global--Font-paragraph--sm-regular);
  }
  &.medium {
    font: var(--lm-global--Font-paragraph--md-regular);

    &.light {
      font: var(--lm-global--Font-paragraph--md-light);
    }
    &.bold {
      font: var(--lm-global--Font-paragraph--md-bold);
    }
  }
  &.large {
    font: var(--lm-global--Font-paragraph--lg-bold);

    @media all and (width < 64rem) {
      margin-block: .75rem;
    }
  }
  &.extralarge {
    font: var(--lm-global--Font-paragraph--xl-medium);
  }
}
