/** ***********************************************************************************
 * Website / Atoms / Typography / Heading
 *********************************************************************************** */

h1.heading-pb {
  font: var(--lm-global--Font-title--hg-light);

  @media all and (width < 64rem) {
    font-size: 2.188rem;
  }

  &.thin {
    font: var(--lm-global--Font-title--hg-thin);
  }
}

h2.heading-pb {
  font: var(--lm-global--Font-title--xl-bold);
  margin-block: 1.12em;
  line-height: 4.5rem;
  letter-spacing: -0.4px;

  @media all and (width < 64rem) {
    font-size: 2.25rem;
    margin-block: 1.625rem;
    line-height: initial;
    letter-spacing: -0.24px;
  }
}
