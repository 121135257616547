/** ***********************************************************************************
 * Website / Atoms / Typography / Label
 *********************************************************************************** */

.label {
  // line-height: 1.222;

  &.extrasmall {
    font: var(--lm-global--Font-label--xs-light);
  }
  &.small {
    font: var(--lm-global--Font-label--sm-regular);

    &.extrabold {
      font: var(--lm-global--Font-label--sm-extrabold);
    }
  }
  &.medium {
    font: var(--lm-global--Font-label--md-medium);
  }
  &.large {
    font: var(--lm-global--Font-label--lg-regular);

    &.light {
      font: var(--lm-global--Font-label--lg-light);
    }

    &.bold {
      font: var(--lm-global--Font-label--lg-bold);
    }
  }
  &.extralarge {
    font: var(--lm-global--Font-label--xl-light);
  }
  &.extraextralarge {
    font: var(--lm-global--Font-label--2xl-bold);
  }
}
