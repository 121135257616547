/** ***************************************************************************
 * App / Organisms / Generic Section
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

section.lm-c-generic_section.lm-m-button_only {

  .lm-c-button-icon {
    grid-column: -1 / 1;
    height: fit-content;
    width: fit-content;
    color: var(--lm-global--palette-dark-blue);

    @media all and (min-width: 64rem) {
      @include utopia.generateClamp((
        "minWidth": 1024,
        "maxWidth": 1440,
        "minSize": 67,
        "maxSize": 97,
        "prefix": "fluid-",
      ));
      grid-column: 8 / span 4;
      margin-block-start: var(--fluid-67-97);
    }
  }

}
