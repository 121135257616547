/** ***************************************************************************
 * Website / Organisms / Header
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

header.lm-c-header {
  @include utopia.generateClamps((
    "minWidth": 678,
    "maxWidth": 1440,
    "pairs": (
      (60, 84),
    ),
    "prefix": "fluid-",
  ));
  --lm-c-header__logo--Height: var(--fluid-60-84);
  --lm-c-header__hamburger--Width: 1.625rem;  /* 26px */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media all and (min-width: 48rem) {
    align-items: flex-start;
  }


  /**
   * Logo
   */
  > a svg {
    height: var(--lm-c-header__logo--Height);
  }


  /**
   * Hamburger
   */
  > label {
    width: var(--lm-c-header__hamburger--Width);

    @media all and (min-width: 48rem) {
      display: none;
    }

    @media all and not (pointer:coarse) {
      cursor: none;
    }
  }


  /**
   * Navigation
   */
  > .lm-c-navigation {
    z-index: 999;
  }

}
