/** ***************************************************************************
 * Website / Organisms / Partners Slider
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

.lm-c-partners_slider {

  > div {
    @include utopia.generateClamp((
      "minWidth": 768,
      "maxWidth": 1440,
      "minSize": 8,
      "maxSize": 13,
      "prefix": "fluid-",
    ));
    text-align: center;
    padding-block-end: var(--fluid-8-13);
    border-block-end: 1px solid var(--lm-global--palette-dark-grey--27);
    grid-column: -1 / 1;

    @media all and (min-width: 64rem) {
      grid-column: -2 / 2;
    }
  }

  .splide {
    @include utopia.generateClamp((
      "minWidth": 768,
      "maxWidth": 1440,
      "minSize": 16,
      "maxSize": 20,
      "prefix": "fluid-",
    ));
    grid-column: -1 / 1;
    // overflow: hidden;
    padding-block: var(--fluid-16-20);
  }

}
