/** ***********************************************************************************
 * Strong text styles
 *********************************************************************************** */

body {
  --lm-c-page__strong--FontWeight: var(--lm-global--FontWeight-bold);
}



b,
strong {
  font-weight: var(--lm-c-page__strong--FontWeight);
}
