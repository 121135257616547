/** ***************************************************************************
 * Website / Organisms / Quotes Slider
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

.lm-c-quotes_slider {

  .splide {
    @include utopia.generateClamp((
      "minWidth": 768,
      "maxWidth": 1440,
      "minSize": 16,
      "maxSize": 20,
      "prefix": "fluid-",
    ));
    grid-column: -1 / 1;
    // overflow: hidden;
    padding-block: var(--fluid-16-20);

    @media all and (min-width: 64rem) {
      grid-column: -3 / 3;
    }

    .splide__arrow {
      top: 35%;
    }
  }

}
