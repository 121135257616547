/** ***************************************************************************
 * App / Molecules / Video Poster
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

.lm-c-video_poster {
  @include utopia.generateClamp((
    "minWidth": 768,
    "maxWidth": 1440,
    "minSize": 7,
    "maxSize": 26,
    "prefix": "fluid-",
  ));
  grid-column: -1 / 1;
  position: relative;
  border-radius: var(--fluid-7-26);
  overflow: hidden;
  cursor: pointer;

  .lm-c-button.lm-m-primary.lm-m-outline.lm-c-button-play.lm-c-button-play--pb {
    @include utopia.generateClamp((
      "minWidth": 375,
      "maxWidth": 1024,
      "minSize": 132,
      "maxSize": 190,
      "prefix": "fluid-",
    ));
    width: var(--fluid-132-190);
    height: var(--fluid-132-190);
  }

  &:hover {
    .lm-c-button.lm-m-primary.lm-m-outline.lm-c-button-play.lm-c-button-play--pb {
      background-color: var(--lm-c-button-play--hover--BackgroundColor);
      transition: all 0s;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .image-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--lm-global--palette-dark-grey);
    opacity: .5;
  }

  @media all and not (pointer:coarse) {
    cursor: none;

    .lm-c-button-play--pb {
      display: none !important;
    }
  }
}
