/** ***************************************************************************
 * Website / Organisms / Headline
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

section.lm-c-headline {
  @include utopia.generateClamp((
    "minWidth": 375,
    "maxWidth": 1024,
    "minSize": 22,
    "maxSize": 27,
    "prefix": "fluid-",
  ));

  h1 {
    @include utopia.generateClamps((
      "minWidth": 1024,
      "maxWidth": 1440,
      "pairs": (
        (803, 1130),
        (82, 115),
        (45, 63),
      ),
      "prefix": "fluid-",
    ));
    max-width: var(--fluid-803-1130);
    text-align: center;
    margin-block: var(--fluid-22-27);
    margin-inline: auto;
    line-height: 2.125rem;
    position: relative;
    z-index: 9;

    @media all and (min-width: 64rem) {
      margin-block: var(--fluid-45-63);
      line-height: var(--fluid-82-115);
    }
  }

  .main-button-wrapper {
    position: relative;
    margin-block-start: var(--fluid-22-27);
    margin-block-end: 3.25rem;

    @media all and (min-width: 64rem) {
      margin-block-end: -0.75rem;
    }

    > .lm-m-primary.lm-c-button-play.lm-c-button-play--pb {
      margin-inline: auto;
      width: 13.25rem;
      height: 13.25rem;

      @media all and (min-width: 64rem) {
        @include utopia.generateClamp((
          "minWidth": 1024,
          "maxWidth": 1440,
          "minSize": 267,
          "maxSize": 375,
          "prefix": "fluid-",
        ));
        width: var(--fluid-267-375);
        height: var(--fluid-267-375);
        --lm-c-button-play--BackgroundColor: var(--lm-global--palette-white);
        --lm-c-button-play--Color: var(--lm-global-common--palette-kiss);
        --lm-c-button-play--hover--BackgroundColor: var(--lm-global-common--palette-kiss);

        &:hover {
          --lm-c-button-play--Color: var(--lm-global--palette-white);
        }
      }
    }

    > .lm-u-center-absolute {

      > .pulsing-oval {
        @include utopia.generateClamp((
          "minWidth": 1024,
          "maxWidth": 1440,
          "minSize": 407,
          "maxSize": 573,
          "prefix": "fluid-",
        ));
        width: var(--fluid-407-573);
        height: var(--fluid-407-573);
        display: none;
        margin-inline: auto;
        background-color: var(--lm-global--palette-white--25);
        border-radius: 50%;
        animation: pulse 3s ease-in-out infinite;

        @media all and (min-width: 64rem) {
          display: block;
        }
      }

    }

    > .oval {
      @include utopia.generateClamp((
        "minWidth": 1024,
        "maxWidth": 1440,
        "minSize": 685,
        "maxSize": 964,
        "prefix": "fluid-",
      ));
      width: var(--fluid-685-964);
      height: var(--fluid-685-964);
      display: none;
      margin-inline: auto;
      background-color: var(--lm-global--palette-dusty-blue--46);
      border-radius: 50%;
      filter: blur(50px);

      @media all and (min-width: 64rem) {
        display: block;
      }
    }
  }

  .description-wrapper {
    text-align: center;
    max-width: 21rem;
    margin-inline: auto;
    position: relative;
    z-index: 9;

    > p {
      margin-block: .625rem;
      line-height: 1.063rem;

      @media all and (min-width: 64rem) {
        @include utopia.generateClamps((
          "minWidth": 1024,
          "maxWidth": 1440,
          "pairs": (
            (10, 14),
            (17, 24),
          ),
          "prefix": "fluid-",
        ));
        margin-block: var(--fluid-10-14);
        line-height: var(--fluid-17-24);
      }
    }

    @media all and (min-width: 64rem) {
      @include utopia.generateClamp((
        "minWidth": 1024,
        "maxWidth": 1440,
        "minSize": 226,
        "maxSize": 318,
        "prefix": "fluid-",
      ));
      text-align: left;
      max-width: var(--fluid-226-318);
      margin-inline: unset;
    }
  }

}

@keyframes pulse {
	50% {
    scale: 1.21;
    // opacity: 0;
	}

  100% {
    scale: 1;
  }
}
