/** ***************************************************************************
 * App / Molecules / Forms / Field
 * FIXME: make relation to @app-molecules-forms-field explicit
 *************************************************************************** */

.lm-c-field {

}



/**
 * Component
 * 1: contain label
 */

.lm-c-field {
  position: relative;  /* 1 */
}



/**
 * Label
 * 1: put label behind widget
 * 2: extend label to the whole row
 */

.lm-c-field > label:not(:only-child) {
  display: block;
  font: var(--lm-global--Font-label--sm-bold);
  color: var(--lm-global--palette-light-blue);
  padding-block-start: .875rem;
  margin-block-end: .563rem;
  text-align: center;
}

.lm-c-field > label[for] {
  cursor: pointer;
}


.lm-c-field.lm-m-disabled > label {
  cursor: inherit;
}


/**
 * Form widget
 * 1: put widget in front of label
 */

.lm-c-field > label + * {
  z-index: 1;  /* 1 */
}
.lm-c-field > label:only-child {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: .438rem;
}

.lm-c-field input:not([type="checkbox"]):not([type="radio"]),
.lm-c-field textarea {
  --fluid-12-15: clamp(0.75rem, 0.2885rem + 0.7212vi, 0.9375rem);
  background-color: transparent;
  font: var(--lm-global--Font-label--sm-medium);
  color: var(--lm-global--palette-white);
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, .34);
  padding-block: var(--fluid-12-15);
  height: 2.7rem;
  scrollbar-width: none;
}
.lm-c-field input:not([type="checkbox"]):not([type="radio"]):focus-visible,
.lm-c-field textarea:focus-visible {
  outline: none;
}
/* .lm-c-field input:not([type="checkbox"]):not([type="radio"]) {
  box-shadow: 4px 4px 8px 1px rgba(117, 118, 119, 0.183) inset, 4px 4px 10px rgba(0,46,82, 0.112834);
} */
.lm-c-field input[type="password"]:not(:placeholder-shown):not(:-webkit-autofill) {
  font-size: 1.4rem;
  letter-spacing: 7px;
}
.lm-c-field > .input-wrapper {
  position: relative;
  display: inline-block;
}
.lm-c-field > .input-wrapper .eye-icon {
  display: none;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eeye-off%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='eye-off' transform='translate(1, 1)' stroke='%2300538f'%3E%3Cpath d='M10.78,10.78 C9.69219361,11.6091832 8.36761384,12.0685493 7,12.0909091 C2.54545455,12.0909091 0,7 0,7 C0.791566297,5.5248427 1.88944983,4.2360229 3.22,3.22 M5.66363636,2.06181818 C6.10166601,1.95928696 6.55013168,1.90803374 7,1.90907488 C11.4545455,1.90907488 14,7 14,7 C13.6137143,7.72265922 13.1530353,8.40301387 12.6254545,9.03 M8.34909091,8.34909091 C7.8731658,8.85984445 7.15640407,9.07008883 6.47999187,8.89734502 C5.80357966,8.72460121 5.27539879,8.19642034 5.10265498,7.52000813 C4.92991117,6.84359593 5.14015555,6.1268342 5.65090909,5.65090909' id='Shape'%3E%3C/path%3E%3Cline x1='0' y1='0' x2='14' y2='14' id='Path'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  width: 14px;
  height: 14px;
  position: absolute;
  right: 21px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  color: var(--lm-global--palette-light-blue);
}
.lm-c-field > .input-wrapper .eye-icon.active {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcone/eye on%3C/title%3E%3Cg id='Icone/eye-on' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0,7 C0,7 2.54545455,2 7,2 C11.4545455,2 14,7 14,7 C14,7 11.4545455,12 7,12 C2.54545455,12 0,7 0,7 Z' id='Path' stroke='%2300538f'%3E%3C/path%3E%3Cellipse id='Oval' stroke='%2300538f' cx='7' cy='7' rx='1.90909091' ry='1.875'%3E%3C/ellipse%3E%3C/g%3E%3C/svg%3E");
}
.lm-c-field input[type="password"]::placeholder {
  letter-spacing: normal;
}
.lm-c-field input::placeholder,
.lm-c-field textarea::placeholder {
  font: var(--lm-global--Font-paragraph--md-light);
  color: var(--lm-global--palette-white--70);
  line-height: var(--lm-global--FontSize-paragraph--md);
}
.lm-c-field input[type="checkbox"] {
  display: inline-grid;
  place-content: center;
  vertical-align: bottom;
  border-radius: 1px;
  border: 1px solid white;
  background-color: rgba(0,83,143, 0.085029);
  appearance: none;
  width: 0.75rem;
  height: 0.75rem;
  cursor: pointer;
}
.lm-c-field input[type="checkbox"]::before {
  content: "";
  width: 4px;
  height: 4px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* box-shadow: inset 1em 1em var(--form-control-color); */
  border-radius: 3px;
  background-color: white;
}
.lm-c-field input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.lm-c-field input[type="radio"] {
  display: inline-grid;
  place-content: center;
  vertical-align: bottom;
  border-radius: 10px;
  border: 1px solid white;
  background-color: rgba(0,83,143, 0.085029);
  appearance: none;
  width: 0.75rem;
  height: 0.75rem;
  cursor: pointer;
}
.lm-c-field input[type="radio"]::before {
  content: "";
  width: 4px;
  height: 4px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* box-shadow: inset 1em 1em var(--form-control-color); */
  border-radius: 3px;
  background-color: white;
}
.lm-c-field input[type="radio"]:checked::before {
  transform: scale(1);
}
