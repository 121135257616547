/** ***************************************************************************
 * App / Organisms / Generic Section / Base
 *************************************************************************** */

 @use 'node_modules/utopia-core-scss/src/utopia' as utopia;

 section.lm-c-generic_section {
  grid-template-rows: auto auto;
  position: relative;

  > * {
    z-index: 99;
  }

  .circle {
    @include utopia.generateClamps((
      "minWidth": 1024,
      "maxWidth": 1440,
      "pairs": (
        (80, 100),
        (120, 170),
        (145, 204),
      ),
      "prefix": "fluid-",
    ));
    display: none;
    background-color: var(--lm-global--palette-white--65);
    width: var(--fluid-145-204);
    height: var(--fluid-145-204);
    border-radius: 50%;
    position: absolute;
    top: var(--fluid-120-170);
    left: var(--fluid-80-100);

    @media all and (min-width: 64rem) {
      display: block;
    }
  }

  h1 {
    grid-column: -1 / 1;
    font-weight: 500;
    margin-block: 0;
    margin-block-end: 1.375rem;

    @media all and (min-width: 64rem) {
      @include utopia.generateClamps((
        "minWidth": 1024,
        "maxWidth": 1440,
        "pairs": (
          (48, 67),
        ),
        "prefix": "fluid-",
      ));
      margin-block-end: var(--fluid-48-67);
    }
  }

  .left {
    grid-column: -1 / 1;
    margin-block-end: 3.125rem;

    > p {
      @include utopia.generateClamps((
        "minWidth": 1024,
        "maxWidth": 1440,
        "pairs": (
          (23, 32),
        ),
        "prefix": "fluid-",
      ));
      margin-block: 0;
      line-height: var(--fluid-23-32);
    }

    .lm-c-button-icon {
      @include utopia.generateClamps((
        "minWidth": 375,
        "maxWidth": 1440,
        "pairs": (
          (35, 50),
        ),
        "prefix": "fluid-",
      ));
      margin-block-start: var(--fluid-35-50);
      width: fit-content;
    }

    @media all and (min-width: 48rem) {
      grid-column: 1;
    }

    @media all and (min-width: 64rem) {
      grid-column: 3 / span 4;
      margin-block-end: 0;
    }
  }

}
