/** ***************************************************************************
 * App / Organisms / Highlight Section
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

section.lm-c-highlight_section {
  @include utopia.generateClamps((
    "minWidth": 375,
    "maxWidth": 1440,
    "pairs": (
      (27, 50),
      (58, 84),
      (55, 105),
    ),
    "prefix": "fluid-",
  ));

  > .container {
    grid-template-rows: auto 1rem auto var(--fluid-27-50) auto; /* alternative to unique row-gap */
    background-color: white;
    border-radius: 26px;
    padding-block: var(--fluid-55-105) var(--fluid-58-84);
    padding-inline: var(--lm-global--page--paddingsize);

    > p {
      max-width: 21.25rem;
      margin-inline: auto;
      margin-block: 0;
    }

    .subtitle {
      grid-column: -1 / 1;
      grid-row-start: 1;
      text-align: center;

      @media all and (min-width: 64rem) {
        grid-column: 2 / span 5;
        grid-row-start: 1;
        text-align: initial;
        max-width: none;
      }
    }

    .description {
      grid-column: -1 / 1;
      grid-row-start: 3;
      text-align: center;

      @media all and (min-width: 64rem) {
        grid-column: 7 / span 5;
        grid-row-start: 1;
        text-align: initial;
        max-width: none;
      }
    }

    .lm-c-button-icon {
      grid-column: -1 / 1;
      grid-row-start: 5;
      width: fit-content;
      margin-inline: auto;

      @media all and (min-width: 64rem) {
        grid-row-start: 2;
      }
    }

    @media all and (min-width: 64rem) {
      grid-template-rows: auto auto;
      row-gap: var(--fluid-27-50);
    }
  }

  @media all and (min-width: 64rem) {
    grid-column: -2 / 2;
  }

}
