/** ***************************************************************************
 * Website / Organisms / Pre-Footer
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

section.lm-c-pre_footer {
  @include utopia.generateClamp((
    "minWidth": 1080,
    "maxWidth": 1440,
    "minSize": 475,
    "maxSize": 668,
    "prefix": "fluid-",
  ));
  --lm-c-pre_footer--BackgroundColor: var(--lm-global--palette-dark-blue);

  background-color: var(--lm-c-pre_footer--BackgroundColor);
  color: var(--lm-c-page--BackgroundColor);

  .container {
    @include utopia.generateClamp((
      "minWidth": 1080,
      "maxWidth": 1440,
      "minSize": 45,
      "maxSize": 64,
      "prefix": "fluid-",
    ));
    @include utopia.generateClamp((
      "minWidth": 375,
      "maxWidth": 1440,
      "minSize": 20,
      "maxSize": 89,
      "prefix": "fluid-",
    ));
    // height: var(--fluid-475-668);
    max-width: var(--lm-c-homepage--MaxContentWidth);
    margin-inline: auto;

    display: grid;
    grid-template-columns: var(--lm-c-page--Grid-Template);
    grid-gap: 0 var(--lm-c-page--Grid-Gap);
    padding-inline: var(--lm-global--page--paddingsize);
    padding-block-start: 1.625rem;
    padding-block-end: var(--fluid-20-89);

    @media all and (min-width: 64rem) {
      padding-block-start: var(--fluid-45-64);
    }
  }

  .language-selection {
    @include utopia.generateClamp((
      "minWidth": 1080,
      "maxWidth": 1440,
      "minSize": 10,
      "maxSize": 14,
      "prefix": "fluid-",
    ));
    color: var(--lm-global--palette-white--30);
    grid-column: -1 / 1;

    > a {
      margin-inline-end: var(--fluid-10-14);

      &:hover {
        color: var(--lm-global--palette-aqua);
      }
    }

    .is-active {
      color: var(--lm-global--palette-white);
    }

    @media all and (min-width: 64rem) {
      grid-column: 2 / span 3;
    }
  }

  .about-label {
    grid-column: -1 / 1;
    max-width: 60vw;
    margin-block: 1.625rem;

    p {
      margin: 0;
    }

    @media all and (min-width: 64rem) {
      grid-column: 9 / span 3;
      margin-block-start: 0;
    }
  }

  form {
    @include utopia.generateClamps((
      "minWidth": 1080,
      "maxWidth": 1440,
      "pairs": (
        (14, 19),
        (100, 140),
      ),
      "prefix": "fluid-",
    ));
    row-gap: var(--fluid-14-19);

    label {
      display: none !important;
    }

    .error-text.general {
      grid-column: -1 / 1;
      font: var(--lm-global--Font-label--md-medium);
      color: rgb(255, 118, 118);

      @media all and (min-width: 64rem) {
        grid-column: -2 / 2;
      }
    }

    .fields {

      input,
      textarea {
        width: 100%;
      }
      textarea {
        margin-bottom: -5px;
      }
    }

    @for $i from 1 through 3 {
      .lm-c-field:nth-of-type(#{$i}) {
        grid-column: -1 / 1;

        @media all and (min-width: 64rem) {
          --currentIndex: calc(#{$i} - 1);
          --span: 3;
          @if $i == 3 {
            --span: 4;
          }
          grid-column: calc(2 + 3 * var(--currentIndex)) / span var(--span);
          align-self: end;
        }
      }
    }

    .action-buttons {
      grid-column: -2 / 2;
      justify-self: end;
    }

    .lm-c-button-icon {
      color: var(--lm-global--palette-sky-blue);
      transition: opacity .3s linear;

      &:hover {
        --lm-c-button-icon--hover__content--Color: var(--lm-c-pre_footer--BackgroundColor);

        &:before {
          color: var(--lm-global--palette-sky-blue);
        }
      }
    }

    @media all and (min-width: 64rem) {
      padding-block-start: var(--fluid-100-140);
    }
  }

  h1 {
    grid-column: -1 / 1;
    opacity: .45;
    margin-block-start: .825rem;
    margin-block-end: 0;

    &.heading-pb {
      font-size: 2.625rem;
    }

    span {
      font-weight: var(--lm-global--FontWeight-medium);
    }

    @media all and (min-width: 64rem) {
      opacity: 1;

      &.heading-pb {
        font-size: var(--lm-global--FontSize-title--hg);
      }
    }
  }
}
