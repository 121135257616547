/** ***************************************************************************
 * Molecules / Slider / Slider
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;
@import '@splidejs/splide/dist/css/splide.min.css';

.splide {
  @include utopia.generateClamp((
    "minWidth": 375,
    "maxWidth": 1440,
    "minSize": 45,
    "maxSize": 85,
    "prefix": "fluid-",
  ));
  --splide-items-gap: var(--fluid-45-85);

  &__list {
    align-items: center;
  }

  &__slide {
    img {
      // max-height: 100%;
      // height: 100%;
      // object-fit: cover;
      // vertical-align: middle;
      animation: fadeIn ease 1s;
    }

    &.quote {
      text-align: center;

      blockquote {
        @include utopia.generateClamp((
          "minWidth": 375,
          "maxWidth": 1440,
          "minSize": 335,
          "maxSize": 666,
          "prefix": "fluid-",
        ));
        @include utopia.generateClamps((
          "minWidth": 1024,
          "maxWidth": 1440,
          "pairs": (
            (26, 36),
            (59, 84),
            (71, 100)
          ),
          "prefix": "fluid-",
        ));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;

        > p {
          line-height: var(--fluid-26-36);
          order: 1;
          margin-block-start: 0;
          margin-block-end: var(--fluid-59-84);
        }

        &:before {
          content: "“";
          font-size: var(--fluid-71-100);
          font-weight: bold;
          height: 4.3rem;
        }
        &:after {
          content: "”";
          font-size: var(--fluid-71-100);
          font-weight: bold;
          height: 4.3rem;
        }

        @media (min-width: 28rem) {
          flex-wrap: nowrap;

          > p {
            max-width: var(--fluid-335-666);
            order: unset;
            margin-block: var(--fluid-59-84);
          }
        }
      }

      .author,
      .company {
        margin-block: 0;
      }

      .author {
        margin-block-end: .25rem;
      }
    }

    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

  }

  &__arrows {
    display: none;

    .splide__arrow {
      @include utopia.generateClamps((
        "minWidth": 1024,
        "maxWidth": 1440,
        "pairs": (
          (10, 14),
          (65, 91),
        ),
        "prefix": "fluid-",
      ));
      opacity: 1;
      border: 1px solid var(--lm-global-common--palette-kiss);
      color: var(--lm-global-common--palette-kiss);
      width: var(--fluid-65-91);
      height: var(--fluid-65-91);

      svg {
        z-index: 1;
        width: var(--fluid-10-14);
        height: var(--fluid-10-14);
      }

      &--prev {
        left: -5em;
      }

      &--next {
        right: -5em;
      }

      &:hover {
        color: var(--lm-global--palette-white);
        opacity: 1;
      }

      &:disabled {
        background-color: transparent;
        color: var(--lm-global-common--palette-kiss);
      }

    }

    @media (min-width: 64rem) {
      display: block;
    }
  }

  .splide__pagination {
    @include utopia.generateClamps((
      "minWidth": 1024,
      "maxWidth": 1440,
      "pairs": (
        (10, 14),
        (17, 24),
        (26, 36),
      ),
      "prefix": "fluid-",
    ));
    counter-reset: pagination-num;
    background-color: transparent;
    position: relative;
    margin-block-start: var(--fluid-26-36);

    &__page {
      color: currentColor;
      background-color: transparent;
      opacity: 1;
      width: var(--fluid-17-24);
      height: var(--fluid-17-24);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: none;

      &.is-active {
        color: var(--lm-global--palette-white);
        background-color: var(--lm-global-common--palette-kiss);
        transform: none;
      }

      &:before {
        counter-increment: pagination-num;
        content: counter( pagination-num );
        font-size: var(--fluid-10-14);
      }

      &:hover {
        color: var(--lm-global--palette-white);
        background-color: var(--lm-global-common--palette-kiss);
      }
    }
  }

  // &.is-focus-in .splide__arrow:focus {
  //   outline: var(--ap-global--Outline--black);
  // }

}
