/** ***************************************************************************
 * Website / Organisms / Footer
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

footer.lm-c-footer {
  @include utopia.generateClamp((
    "minWidth": 375,
    "maxWidth": 1440,
    "minSize": 28,
    "maxSize": 39,
    "prefix": "fluid-",
  ));
  height: var(--fluid-28-39);
  max-width: var(--lm-c-homepage--MaxContentWidth);
  margin-inline: auto;

  background-color: var(--lm-c-page--BackgroundColor);
  color: var(--lm-global--palette-prussian-blue);

  display: grid;
  grid-template-columns: var(--lm-c-page--Grid-Template);
  grid-gap: 0 var(--lm-c-page--Grid-Gap);
  padding-inline: var(--lm-global--page--paddingsize);
  align-items: center;

  .copyright {

    @media all and (min-width: 64rem) {
      grid-column: 2 / span 5;
    }
  }

  .links {
    @include utopia.generateClamp((
      "minWidth": 375,
      "maxWidth": 1440,
      "minSize": 12,
      "maxSize": 32,
      "prefix": "fluid-",
    ));
    justify-self: end;

    display: flex;
    height: calc(var(--fluid-28-39)* 0.6);

    > span {
      display: flex;
      align-items: center;
      padding-inline-end: var(--fluid-12-32);
      border-inline-end: 1px solid;
    }

    a {
      margin-inline-start: var(--fluid-12-32);
    }

    @media all and (min-width: 64rem) {
      grid-column: 7 / span 5;
    }
  }
}
