/** ***************************************************************************
 * Website / Organisms / Header / Navigation
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

.lm-c-navigation {
  --lm-c-navigation--BackgroundColor: var(--lm-global--palette-dusty-blue--40);
  --lm-c-navigation--ColumnGap: 2.75em;  /* 55px */
  --lm-c-navigation--Font: var(--lm-global--Font-title--lg-bold);
  --lm-c-navigation--PaddingBlock: 3.5rem;  /* 56px, eyeballed */

  @media all and (min-width: 48rem) {
    --lm-c-navigation--BackgroundColor: transparent;
    --lm-c-navigation--Font: var(--lm-global--Font-title--md-regular);
  }


  /**
   * Close button
   */

  --lm-c-navigation__close--BorderColor: var(--lm-global--palette-white--49);
  --lm-c-navigation__close--Size: 1rem;  /* 16px */


  /**
   * Navigation items
   */

  --lm-c-navigation__items--MarginBlockStart: 1.5rem;
  --lm-c-navigation__language--MarginBlockStart: 2.5rem;  /* 40px */
  --lm-c-navigation__items--RowGap: 1.5rem;  /* 24px */


  /**
   * Navigation item
   */

  --lm-c-navigation__item--Color: var(--lm-global--palette-dark-blue);
  --lm-c-navigation__item--RowGap: .65em;  /* 13px */

  --lm-c-navigation__item-decoration--BackgroundColor: currentcolor;

  --lm-c-navigation__item--m-current--Color: var(--lm-global--palette-white);

  @media all and (min-width: 48rem) {
    /* --lm-c-navigation__item--Color: var(--lm-global--palette-white); */
    --lm-c-navigation__item--m-current--Color: var(--lm-c-navigation__item--Color);
    --lm-c-navigation__item--RowGap: .5em;  /* 7px */
    --lm-c-navigation__item-decoration--BackgroundColor: var(--lm-global--palette-dark-blue);
  }


  /**
   * Logo
   */

  --lm-c-navigation__logo--Height: 3.813rem;  /* 61px */

}


#lm-c-header__toggle:not(:checked) ~ .lm-c-navigation {
  opacity: 0;
  pointer-events: none;
  transition: none;

  @media all and (min-width: 48rem) {
    opacity: 1;
    pointer-events: initial;
  }
}

.lm-c-navigation {
  background-color: var(--lm-c-navigation--BackgroundColor);
  color: var(--lm-global--palette-dark-blue);
  display: grid;
  font: var(--lm-c-navigation--Font);
  grid-template-rows: 4fr auto 10fr;
  inset: 0;
  overflow: auto;
  padding-block: var(--lm-c-navigation--PaddingBlock);
  place-items: center;
  position: fixed;
  text-align: center;
  transition: opacity .15s linear;

  @media all and (min-width: 48rem) {
    column-gap: var(--lm-c-navigation--ColumnGap);
    display: flex;
    flex-direction: row;
    padding-block: 0;
    position: static;
  }

  @supports not (inset: 0) {
    bottom: 0;
    left: 0;
    padding-top: var(--lm-c-navigation--PaddingBlock);
    right: 0;
    top: 0;

    @media all and (min-width: 48rem) {
      padding-top: 0
    }
  }


  /**
   * Close button
   * 1: reset
   */
  > label {
    align-self: end;
    border-radius: 50%;
    border: 1px solid var(--lm-c-navigation__close--BorderColor);
    box-sizing: content-box;  /* 1 */
    display: inline-block;
    /* height: var(--lm-c-navigation__close--Size); */
    padding: calc(var(--lm-c-navigation__close--Size) * 1.125);
    /* width: var(--lm-c-navigation__close--Size); */

    .lm-c-button.lm-m-close--pb {
      --lm-c-button--m-close--Background-Color: transparent;
      --lm-c-button--m-close--Color: var(--lm-global--palette-dark-grey);
      border-color: var(--lm-global--palette-dark-grey--49);
      padding: .93rem;
      border-radius: 50%;
      pointer-events: none;

      svg {
        width: 1.2rem;
      }
    }

    svg {
      display: block;  /* 1 */
    }

    @media all and (min-width: 48rem) {
      display: none;
    }

    @media all and not (pointer:coarse) {
      cursor: none;
    }
  }


  .content-wrapper {
    @include utopia.generateClamps((
      "minWidth": 768,
      "maxWidth": 1440,
      "pairs": (
        (10, 13),
        (13, 18),
      ),
      "prefix": "fluid-",
    ));

    @media all and (min-width: 48rem) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  /**
   * Navigation items
   * 1: reset
   */
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;  /* 1 */
    margin-block: var(--lm-c-navigation__items--MarginBlockStart) 0;
    margin-inline: 0;  /* 1 */
    padding: 0;  /* 1 */
    gap: var(--lm-c-navigation__items--RowGap);

    @media all and (min-width: 48rem) {
      flex-direction: row;
      gap: var(--fluid-10-13);
      margin-block: 0;
      padding-inline-start: var(--fluid-13-18);
    }

    @supports not (margin-block: 0 0) {
      margin: var(--lm-c-navigation__items--MarginBlockStart) 0 0 0;
    }
  }


  /**
   * Navigation item
   * 1: reset
   * 2: contain decoration
   */
  li {
    color: var(--lm-c-navigation__item--Color);

    &.lm-m-current {
      color: var(--lm-c-navigation__item--m-current--Color);
      pointer-events: none;

      @media all and (min-width: 48rem) {
        a::after {
          transform: scaleX(1);
        }
      }
    }

    &:first-child a {
      background-color: var(--lm-global--palette-light-blue);
      color: var(--lm-global--palette-white);
    }

    a {
      display: inline-block;
      border-color: var(--lm-global--palette-light-blue);
      font-size: 1.125rem;
      color: var(--lm-c-navigation__item--Color);

      @supports not (padding-block-end: 0) {
        padding-bottom: var(--lm-c-navigation__item--RowGap);
      }

      &:hover {
        background-color: transparent;
        border-color: var(--lm-global-common--palette-kiss);
      }

      @media all and (min-width: 48rem) {
        font: var(--lm-global--Font-label--md-medium);
      }
    }
  }


  /**
   * Logo
   */

  > svg {
    height: var(--lm-c-navigation__logo--Height);
    display: inline-block;
    align-self: end;

    @media all and (min-width: 48rem) {
      display: none;
    }
  }

  .language-selection {
    display: flex;
    align-items: center;
    gap: 1.063rem;
    margin-block-start: var(--lm-c-navigation__language--MarginBlockStart);
    padding-inline-end: .313rem;

    > a {
      font-size: 2.5rem;
      /* margin-inline-end: 1.063rem; */
      vertical-align: middle;
      color: var(--lm-global--palette-dark-blue--31);

      &.is-active {
        color: var(--lm-global--palette-dark-blue);
      }

      &:hover {
        color: var(--lm-global-common--palette-kiss);
      }

      @media all and (min-width: 48rem) {
        font: var(--lm-global--Font-label--sm-regular);
        margin-inline-end: var(--fluid-10-13);
        color: var(--lm-global--palette-prussian-blue);

        &.is-active {
          font: var(--lm-global--Font-label--sm-extrabold);
          color: var(--lm-global--palette-light-blue);
        }

        &:hover {
          color: var(--lm-global-common--palette-kiss);
        }
      }
    }

    @media all and (min-width: 48rem) {
      margin-block: 0;
      gap: 0;
      border-inline-end: 1px solid var(--lm-global--palette-light-blue--45);
    }
  }
}
