/** ***************************************************************************
 * Website / Organisms / PB / Testimonials
 *************************************************************************** */

section.lm-c-testimonials {

  .title {
    grid-column: -1 / 1;
    text-align: center;
  }

  .lm-c-video_poster {
    --fluid-385-766: clamp(24.0625rem, 15.6778rem + 35.7746vi, 47.875rem);
    height: var(--fluid-385-766);

    @media all and (min-width: 64rem) {
      grid-column: 4 / span 6;
    }
  }

  .description {
    align-self: end;
    display: grid;
    grid-column: -1 / 1;
    justify-items: center;
    margin-block-start: 1.625rem;

    @media all and (min-width: 64rem) {
      grid-column: 10 / span 3;
      justify-items: start;
      margin-inline-start: .563rem;
    }

    .label {
      text-align: center;
      line-height: .938rem;

      @media all and (min-width: 64rem) {
        line-height: 1.125rem;
        text-align: left;
      }
    }
    .company-name {
      @media all and (min-width: 64rem) {
        margin-block: .57em 1.16em;
      }
    }
  }

}
