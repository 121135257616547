/** ***************************************************************************
 * App / Organisms / Generic Section
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

section.lm-c-generic_section.lm-m-list {

  ol {
    grid-column: -1 / 1;
    list-style-type: none;
    counter-reset: li;
    padding: 0;
    margin: 0;
    font: var(--lm-global--Font-paragraph--md-regular);

    li {
      @include utopia.generateClamp((
        "minWidth": 375,
        "maxWidth": 1440,
        "minSize": 27,
        "maxSize": 38,
        "prefix": "fluid-",
      ));
      display: flex;
      align-items: flex-start;
      border-block-end: 1px solid var(--lm-global--palette-dark-grey--27);
      padding-block: var(--fluid-27-38);

      div > p {
        display: inline-block;
        margin-block: 0;
      }

      &:first-child {
        padding-block-start: 0;
      }

      &:before {
        @include utopia.generateClamp((
          "minWidth": 375,
          "maxWidth": 1440,
          "minSize": 25,
          "maxSize": 35,
          "prefix": "fluid-",
        ));
        counter-increment: li;
        content: counter(li, decimal-leading-zero);
        margin-right: var(--fluid-25-35);
        font-size: var(--lm-global--FontSize-label--lg);
        color: var(--lm-global--palette-dark-grey--56);
      }
    }

    @media all and (min-width: 48rem) {
      grid-column: 2;
      width: 87%;
      justify-self: end;
    }

    @media all and (min-width: 64rem) {
      grid-column: 7 / span 5;
    }
  }

}
