/** ***************************************************************************
 * Website / Organisms / PB / Tutorial
 *************************************************************************** */

section.lm-c-tutorial {
  position: relative;

  .lm-c-video_poster {
    --fluid-188-766: clamp(11.75rem, -0.9701rem + 54.2723vi, 47.875rem);
    height: var(--fluid-188-766);

    .image-mask {
      opacity: 0.84;
    }
  }

  .description {
    padding-block-start: 1.313rem;
    grid-column: -1 / 1;

    @media all and (min-width: 48rem) {
      --fluid-2473-44: clamp(1.5456rem, 0.1692rem + 2.8676vi, 2.75rem);
      --fluid-28-65: clamp(1.75rem, -0.8929rem + 5.506vi, 4.0625rem);
      --fluid-192-27: clamp(1.2rem, 0.6429rem + 1.1607vi, 1.6875rem);
      justify-items: end;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: var(--fluid-2473-44) var(--fluid-28-65);
      padding-block-start: var(--fluid-192-27);
      border-top: 1px solid currentColor;
      color: var(--lm-global--palette-white);
    }

    .label {
      text-align: center;
      line-height: .938rem;

      > * {
        margin: 0;
      }

      @media all and (min-width: 64rem) {
        line-height: 1.125rem;
        text-align: left;
      }
    }
  }

}
